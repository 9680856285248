import React, { ReactNode, useRef } from "react";
import { useReactToPrint } from "react-to-print";

const pageStyleToPrint = new Map([
  ["A4", `size: A4; margin: 0.3cm 0 0.3cm 0;`],
  ["16X10", `size: 16cm 10cm; margin: 0.3cm 0 0;`],
]);

export default function usePrintInBrowser({
  type,
  list,
  onAfterPrint,
}: {
  type: "A4" | "16X10";
  list: ReactNode[];
  onAfterPrint?: () => void;
}) {
  const componentRef = useRef(null);

  const pageStyle = `@media print {
    @page {
      ${pageStyleToPrint.get(type)}
    }    
  }`;

  const handlePrint = useReactToPrint({
    content: () => componentRef?.current,
    pageStyle,
    onAfterPrint,
  });

  const PageToPrint = <div ref={componentRef}>{list}</div>;

  return { PageToPrint, handlePrint };
}
